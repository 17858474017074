var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/* MIT https://github.com/fabiospampinato/cash */
(function () {
  "use strict";

  var propMap = {
    /* GENERAL */
    "class": "className",
    contenteditable: "contentEditable",

    /* LABEL */
    "for": "htmlFor",

    /* INPUT */
    readonly: "readOnly",
    maxlength: "maxLength",
    tabindex: "tabIndex",

    /* TABLE */
    colspan: "colSpan",
    rowspan: "rowSpan",

    /* IMAGE */
    usemap: "useMap"
  };

  function attempt(fn, arg) {
    try {
      return fn(arg);
    } catch (_a) {
      return arg;
    }
  }

  var doc = document,
      win = window,
      docEle = doc.documentElement,
      createElement = doc.createElement.bind(doc),
      div = createElement("div"),
      table = createElement("table"),
      tbody = createElement("tbody"),
      tr = createElement("tr"),
      isArray = Array.isArray,
      ArrayPrototype = Array.prototype,
      concat = ArrayPrototype.concat,
      filter = ArrayPrototype.filter,
      indexOf = ArrayPrototype.indexOf,
      map = ArrayPrototype.map,
      push = ArrayPrototype.push,
      slice = ArrayPrototype.slice,
      some = ArrayPrototype.some,
      splice = ArrayPrototype.splice;
  var idRe = /^#(?:[\w-]|\\.|[^\x00-\xa0])*$/,
      classRe = /^\.(?:[\w-]|\\.|[^\x00-\xa0])*$/,
      htmlRe = /<.+>/,
      tagRe = /^\w+$/; // @require ./variables.ts

  function find(selector, context) {
    var isFragment = isDocumentFragment(context);
    return !selector || !isFragment && !isDocument(context) && !isElement(context) ? [] : !isFragment && classRe.test(selector) ? context.getElementsByClassName(selector.slice(1)) : !isFragment && tagRe.test(selector) ? context.getElementsByTagName(selector) : context.querySelectorAll(selector);
  } // @require ./find.ts
  // @require ./variables.ts


  var Cash =
  /** @class */
  function () {
    function Cash(selector, context) {
      if (!selector) return;
      if (isCash(selector)) return selector;
      var eles = selector;

      if (isString(selector)) {
        var ctx = (isCash(context) ? context[0] : context) || doc;
        eles = idRe.test(selector) && "getElementById" in ctx ? ctx.getElementById(selector.slice(1)) : htmlRe.test(selector) ? parseHTML(selector) : find(selector, ctx);
        if (!eles) return;
      } else if (isFunction(selector)) {
        return this.ready(selector); //FIXME: `fn.ready` is not included in `core`, but it's actually a core functionality
      }

      if (eles.nodeType || eles === win) eles = [eles];
      (this || _global).length = eles.length;

      for (var i = 0, l = (this || _global).length; i < l; i++) {
        (this || _global)[i] = eles[i];
      }
    }

    Cash.prototype.init = function (selector, context) {
      return new Cash(selector, context);
    };

    return Cash;
  }();

  var fn = Cash.prototype,
      cash = fn.init;
  cash.fn = cash.prototype = fn; // Ensuring that `cash () instanceof cash`

  fn.length = 0;
  fn.splice = splice; // Ensuring a cash collection gets printed as array-like in Chrome's devtools

  if (typeof Symbol === "function") {
    // Ensuring a cash collection is iterable
    fn[Symbol["iterator"]] = ArrayPrototype[Symbol["iterator"]];
  }

  fn.map = function (callback) {
    return cash(concat.apply([], map.call(this || _global, function (ele, i) {
      return callback.call(ele, i, ele);
    })));
  };

  fn.slice = function (start, end) {
    return cash(slice.call(this || _global, start, end));
  }; // @require ./cash.ts


  var dashAlphaRe = /-([a-z])/g;

  function camelCase(str) {
    return str.replace(dashAlphaRe, function (match, letter) {
      return letter.toUpperCase();
    });
  }

  cash.guid = 1; // @require ./cash.ts

  function matches(ele, selector) {
    var matches = ele && (ele["matches"] || ele["webkitMatchesSelector"] || ele["msMatchesSelector"]);
    return !!matches && !!selector && matches.call(ele, selector);
  }

  function isCash(x) {
    return x instanceof Cash;
  }

  function isWindow(x) {
    return !!x && x === x.window;
  }

  function isDocument(x) {
    return !!x && x.nodeType === 9;
  }

  function isDocumentFragment(x) {
    return !!x && x.nodeType === 11;
  }

  function isElement(x) {
    return !!x && x.nodeType === 1;
  }

  function isBoolean(x) {
    return typeof x === "boolean";
  }

  function isFunction(x) {
    return typeof x === "function";
  }

  function isString(x) {
    return typeof x === "string";
  }

  function isUndefined(x) {
    return x === undefined;
  }

  function isNull(x) {
    return x === null;
  }

  function isNumeric(x) {
    return !isNaN(parseFloat(x)) && isFinite(x);
  }

  function isPlainObject(x) {
    if (typeof x !== "object" || x === null) return false;
    var proto = Object.getPrototypeOf(x);
    return proto === null || proto === Object.prototype;
  }

  cash.isWindow = isWindow;
  cash.isFunction = isFunction;
  cash.isArray = isArray;
  cash.isNumeric = isNumeric;
  cash.isPlainObject = isPlainObject;

  fn.get = function (index) {
    if (isUndefined(index)) return slice.call(this || _global);
    index = Number(index);
    return (this || _global)[index < 0 ? index + (this || _global).length : index];
  };

  fn.eq = function (index) {
    return cash(this.get(index));
  };

  fn.first = function () {
    return this.eq(0);
  };

  fn.last = function () {
    return this.eq(-1);
  };

  function each(arr, callback, _reverse) {
    if (_reverse) {
      var i = arr.length;

      while (i--) {
        if (callback.call(arr[i], i, arr[i]) === false) return arr;
      }
    } else if (isPlainObject(arr)) {
      var keys = Object.keys(arr);

      for (var i = 0, l = keys.length; i < l; i++) {
        var key = keys[i];
        if (callback.call(arr[key], key, arr[key]) === false) return arr;
      }
    } else {
      for (var i = 0, l = arr.length; i < l; i++) {
        if (callback.call(arr[i], i, arr[i]) === false) return arr;
      }
    }

    return arr;
  }

  cash.each = each;

  fn.each = function (callback) {
    return each(this || _global, callback);
  };

  fn.prop = function (prop, value) {
    if (!prop) return;

    if (isString(prop)) {
      prop = propMap[prop] || prop;
      if (arguments.length < 2) return (this || _global)[0] && (this || _global)[0][prop];
      return this.each(function (i, ele) {
        ele[prop] = value;
      });
    }

    for (var key in prop) {
      this.prop(key, prop[key]);
    }

    return this || _global;
  };

  fn.removeProp = function (prop) {
    return this.each(function (i, ele) {
      delete ele[propMap[prop] || prop];
    });
  };

  function extend() {
    var sources = [];

    for (var _i = 0; _i < arguments.length; _i++) {
      sources[_i] = arguments[_i];
    }

    var deep = isBoolean(sources[0]) ? sources.shift() : false,
        target = sources.shift(),
        length = sources.length;
    if (!target) return {};
    if (!length) return extend(deep, cash, target);

    for (var i = 0; i < length; i++) {
      var source = sources[i];

      for (var key in source) {
        if (deep && (isArray(source[key]) || isPlainObject(source[key]))) {
          if (!target[key] || target[key].constructor !== source[key].constructor) target[key] = new source[key].constructor();
          extend(deep, target[key], source[key]);
        } else {
          target[key] = source[key];
        }
      }
    }

    return target;
  }

  cash.extend = extend;

  fn.extend = function (plugins) {
    return extend(fn, plugins);
  }; // @require ./matches.ts
  // @require ./type_checking.ts


  function getCompareFunction(comparator) {
    return isString(comparator) ? function (i, ele) {
      return matches(ele, comparator);
    } : isFunction(comparator) ? comparator : isCash(comparator) ? function (i, ele) {
      return comparator.is(ele);
    } : !comparator ? function () {
      return false;
    } : function (i, ele) {
      return ele === comparator;
    };
  }

  fn.filter = function (comparator) {
    var compare = getCompareFunction(comparator);
    return cash(filter.call(this || _global, function (ele, i) {
      return compare.call(ele, i, ele);
    }));
  }; // @require collection/filter.ts


  function filtered(collection, comparator) {
    return !comparator ? collection : collection.filter(comparator);
  } // @require ./type_checking.ts


  var splitValuesRe = /\S+/g;

  function getSplitValues(str) {
    return isString(str) ? str.match(splitValuesRe) || [] : [];
  }

  fn.hasClass = function (cls) {
    return !!cls && some.call(this || _global, function (ele) {
      return isElement(ele) && ele.classList.contains(cls);
    });
  };

  fn.removeAttr = function (attr) {
    var attrs = getSplitValues(attr);
    return this.each(function (i, ele) {
      if (!isElement(ele)) return;
      each(attrs, function (i, a) {
        ele.removeAttribute(a);
      });
    });
  };

  function attr(attr, value) {
    if (!attr) return;

    if (isString(attr)) {
      if (arguments.length < 2) {
        if (!(this || _global)[0] || !isElement((this || _global)[0])) return;

        var value_1 = (this || _global)[0].getAttribute(attr);

        return isNull(value_1) ? undefined : value_1;
      }

      if (isUndefined(value)) return this || _global;
      if (isNull(value)) return this.removeAttr(attr);
      return this.each(function (i, ele) {
        if (!isElement(ele)) return;
        ele.setAttribute(attr, value);
      });
    }

    for (var key in attr) {
      this.attr(key, attr[key]);
    }

    return this || _global;
  }

  fn.attr = attr;

  fn.toggleClass = function (cls, force) {
    var classes = getSplitValues(cls),
        isForce = !isUndefined(force);
    return this.each(function (i, ele) {
      if (!isElement(ele)) return;
      each(classes, function (i, c) {
        if (isForce) {
          force ? ele.classList.add(c) : ele.classList.remove(c);
        } else {
          ele.classList.toggle(c);
        }
      });
    });
  };

  fn.addClass = function (cls) {
    return this.toggleClass(cls, true);
  };

  fn.removeClass = function (cls) {
    if (arguments.length) return this.toggleClass(cls, false);
    return this.attr("class", "");
  };

  function pluck(arr, prop, deep, until) {
    var plucked = [],
        isCallback = isFunction(prop),
        compare = until && getCompareFunction(until);

    for (var i = 0, l = arr.length; i < l; i++) {
      if (isCallback) {
        var val_1 = prop(arr[i]);
        if (val_1.length) push.apply(plucked, val_1);
      } else {
        var val_2 = arr[i][prop];

        while (val_2 != null) {
          if (until && compare(-1, val_2)) break;
          plucked.push(val_2);
          val_2 = deep ? val_2[prop] : null;
        }
      }
    }

    return plucked;
  }

  function unique(arr) {
    return arr.length > 1 ? filter.call(arr, function (item, index, self) {
      return indexOf.call(self, item) === index;
    }) : arr;
  }

  cash.unique = unique;

  fn.add = function (selector, context) {
    return cash(unique(this.get().concat(cash(selector, context).get())));
  }; // @require core/type_checking.ts
  // @require core/variables.ts


  function computeStyle(ele, prop, isVariable) {
    if (!isElement(ele)) return;
    var style = win.getComputedStyle(ele, null);
    return isVariable ? style.getPropertyValue(prop) || undefined : style[prop] || ele.style[prop];
  } // @require ./compute_style.ts


  function computeStyleInt(ele, prop) {
    return parseInt(computeStyle(ele, prop), 10) || 0;
  }

  var cssVariableRe = /^--/; // @require ./variables.ts

  function isCSSVariable(prop) {
    return cssVariableRe.test(prop);
  } // @require core/camel_case.ts
  // @require core/cash.ts
  // @require core/each.ts
  // @require core/variables.ts
  // @require ./is_css_variable.ts


  var prefixedProps = {},
      style = div.style,
      vendorsPrefixes = ["webkit", "moz", "ms"];

  function getPrefixedProp(prop, isVariable) {
    if (isVariable === void 0) {
      isVariable = isCSSVariable(prop);
    }

    if (isVariable) return prop;

    if (!prefixedProps[prop]) {
      var propCC = camelCase(prop),
          propUC = "" + propCC[0].toUpperCase() + propCC.slice(1),
          props = (propCC + " " + vendorsPrefixes.join(propUC + " ") + propUC).split(" ");
      each(props, function (i, p) {
        if (p in style) {
          prefixedProps[prop] = p;
          return false;
        }
      });
    }

    return prefixedProps[prop];
  }

  ; // @require core/type_checking.ts
  // @require ./is_css_variable.ts

  var numericProps = {
    animationIterationCount: true,
    columnCount: true,
    flexGrow: true,
    flexShrink: true,
    fontWeight: true,
    gridArea: true,
    gridColumn: true,
    gridColumnEnd: true,
    gridColumnStart: true,
    gridRow: true,
    gridRowEnd: true,
    gridRowStart: true,
    lineHeight: true,
    opacity: true,
    order: true,
    orphans: true,
    widows: true,
    zIndex: true
  };

  function getSuffixedValue(prop, value, isVariable) {
    if (isVariable === void 0) {
      isVariable = isCSSVariable(prop);
    }

    return !isVariable && !numericProps[prop] && isNumeric(value) ? value + "px" : value;
  }

  function css(prop, value) {
    if (isString(prop)) {
      var isVariable_1 = isCSSVariable(prop);
      prop = getPrefixedProp(prop, isVariable_1);
      if (arguments.length < 2) return (this || _global)[0] && computeStyle((this || _global)[0], prop, isVariable_1);
      if (!prop) return this || _global;
      value = getSuffixedValue(prop, value, isVariable_1);
      return this.each(function (i, ele) {
        if (!isElement(ele)) return;

        if (isVariable_1) {
          ele.style.setProperty(prop, value);
        } else {
          ele.style[prop] = value;
        }
      });
    }

    for (var key in prop) {
      this.css(key, prop[key]);
    }

    return this || _global;
  }

  ;
  fn.css = css; // @optional ./css.ts
  // @require core/attempt.ts
  // @require core/camel_case.ts

  var JSONStringRe = /^\s+|\s+$/;

  function getData(ele, key) {
    var value = ele.dataset[key] || ele.dataset[camelCase(key)];
    if (JSONStringRe.test(value)) return value;
    return attempt(JSON.parse, value);
  } // @require core/attempt.ts
  // @require core/camel_case.ts


  function setData(ele, key, value) {
    value = attempt(JSON.stringify, value);
    ele.dataset[camelCase(key)] = value;
  }

  function data(name, value) {
    if (!name) {
      if (!(this || _global)[0]) return;
      var datas = {};

      for (var key in (this || _global)[0].dataset) {
        datas[key] = getData((this || _global)[0], key);
      }

      return datas;
    }

    if (isString(name)) {
      if (arguments.length < 2) return (this || _global)[0] && getData((this || _global)[0], name);
      if (isUndefined(value)) return this || _global;
      return this.each(function (i, ele) {
        setData(ele, name, value);
      });
    }

    for (var key in name) {
      this.data(key, name[key]);
    }

    return this || _global;
  }

  fn.data = data; // @optional ./data.ts

  function getDocumentDimension(doc, dimension) {
    var docEle = doc.documentElement;
    return Math.max(doc.body["scroll" + dimension], docEle["scroll" + dimension], doc.body["offset" + dimension], docEle["offset" + dimension], docEle["client" + dimension]);
  } // @require css/helpers/compute_style_int.ts


  function getExtraSpace(ele, xAxis) {
    return computeStyleInt(ele, "border" + (xAxis ? "Left" : "Top") + "Width") + computeStyleInt(ele, "padding" + (xAxis ? "Left" : "Top")) + computeStyleInt(ele, "padding" + (xAxis ? "Right" : "Bottom")) + computeStyleInt(ele, "border" + (xAxis ? "Right" : "Bottom") + "Width");
  }

  each([true, false], function (i, outer) {
    each(["Width", "Height"], function (i, prop) {
      var name = "" + (outer ? "outer" : "inner") + prop;

      fn[name] = function (includeMargins) {
        if (!(this || _global)[0]) return;
        if (isWindow((this || _global)[0])) return outer ? (this || _global)[0]["inner" + prop] : (this || _global)[0].document.documentElement["client" + prop];
        if (isDocument((this || _global)[0])) return getDocumentDimension((this || _global)[0], prop);
        return (this || _global)[0]["" + (outer ? "offset" : "client") + prop] + (includeMargins && outer ? computeStyleInt((this || _global)[0], "margin" + (i ? "Top" : "Left")) + computeStyleInt((this || _global)[0], "margin" + (i ? "Bottom" : "Right")) : 0);
      };
    });
  });
  each(["Width", "Height"], function (index, prop) {
    var propLC = prop.toLowerCase();

    fn[propLC] = function (value) {
      if (!(this || _global)[0]) return isUndefined(value) ? undefined : this || _global;

      if (!arguments.length) {
        if (isWindow((this || _global)[0])) return (this || _global)[0].document.documentElement["client" + prop];
        if (isDocument((this || _global)[0])) return getDocumentDimension((this || _global)[0], prop);
        return (this || _global)[0].getBoundingClientRect()[propLC] - getExtraSpace((this || _global)[0], !index);
      }

      var valueNumber = parseInt(value, 10);
      return this.each(function (i, ele) {
        if (!isElement(ele)) return;
        var boxSizing = computeStyle(ele, "boxSizing");
        ele.style[propLC] = getSuffixedValue(propLC, valueNumber + (boxSizing === "border-box" ? getExtraSpace(ele, !index) : 0));
      });
    };
  }); // @optional ./inner_outer.ts
  // @optional ./normal.ts
  // @require css/helpers/compute_style.ts

  var defaultDisplay = {};

  function getDefaultDisplay(tagName) {
    if (defaultDisplay[tagName]) return defaultDisplay[tagName];
    var ele = createElement(tagName);
    doc.body.insertBefore(ele, null);
    var display = computeStyle(ele, "display");
    doc.body.removeChild(ele);
    return defaultDisplay[tagName] = display !== "none" ? display : "block";
  } // @require css/helpers/compute_style.ts


  function isHidden(ele) {
    return computeStyle(ele, "display") === "none";
  }

  var displayProperty = "___cd";

  fn.toggle = function (force) {
    return this.each(function (i, ele) {
      if (!isElement(ele)) return;
      var show = isUndefined(force) ? isHidden(ele) : force;

      if (show) {
        ele.style.display = ele[displayProperty] || "";

        if (isHidden(ele)) {
          ele.style.display = getDefaultDisplay(ele.tagName);
        }
      } else {
        ele[displayProperty] = computeStyle(ele, "display");
        ele.style.display = "none";
      }
    });
  };

  fn.hide = function () {
    return this.toggle(false);
  };

  fn.show = function () {
    return this.toggle(true);
  }; // @optional ./hide.ts
  // @optional ./show.ts
  // @optional ./toggle.ts


  function hasNamespaces(ns1, ns2) {
    return !ns2 || !some.call(ns2, function (ns) {
      return ns1.indexOf(ns) < 0;
    });
  }

  var eventsNamespace = "___ce",
      eventsNamespacesSeparator = ".",
      eventsFocus = {
    focus: "focusin",
    blur: "focusout"
  },
      eventsHover = {
    mouseenter: "mouseover",
    mouseleave: "mouseout"
  },
      eventsMouseRe = /^(mouse|pointer|contextmenu|drag|drop|click|dblclick)/i; // @require ./variables.ts

  function getEventNameBubbling(name) {
    return eventsHover[name] || eventsFocus[name] || name;
  } // @require ./variables.ts


  function getEventsCache(ele) {
    return ele[eventsNamespace] = ele[eventsNamespace] || {};
  } // @require core/guid.ts
  // @require events/helpers/get_events_cache.ts


  function addEvent(ele, name, namespaces, selector, callback) {
    var eventCache = getEventsCache(ele);
    eventCache[name] = eventCache[name] || [];
    eventCache[name].push([namespaces, selector, callback]);
    ele.addEventListener(name, callback);
  } // @require ./variables.ts


  function parseEventName(eventName) {
    var parts = eventName.split(eventsNamespacesSeparator);
    return [parts[0], parts.slice(1).sort()]; // [name, namespace[]]
  } // @require ./get_events_cache.ts
  // @require ./has_namespaces.ts
  // @require ./parse_event_name.ts


  function removeEvent(ele, name, namespaces, selector, callback) {
    var cache = getEventsCache(ele);

    if (!name) {
      for (name in cache) {
        removeEvent(ele, name, namespaces, selector, callback);
      }
    } else if (cache[name]) {
      cache[name] = cache[name].filter(function (_a) {
        var ns = _a[0],
            sel = _a[1],
            cb = _a[2];
        if (callback && cb.guid !== callback.guid || !hasNamespaces(ns, namespaces) || selector && selector !== sel) return true;
        ele.removeEventListener(name, cb);
      });
    }
  }

  fn.off = function (eventFullName, selector, callback) {
    var _this = this || _global;

    if (isUndefined(eventFullName)) {
      this.each(function (i, ele) {
        if (!isElement(ele) && !isDocument(ele) && !isWindow(ele)) return;
        removeEvent(ele);
      });
    } else if (!isString(eventFullName)) {
      for (var key in eventFullName) {
        this.off(key, eventFullName[key]);
      }
    } else {
      if (isFunction(selector)) {
        callback = selector;
        selector = "";
      }

      each(getSplitValues(eventFullName), function (i, eventFullName) {
        var _a = parseEventName(eventFullName),
            nameOriginal = _a[0],
            namespaces = _a[1],
            name = getEventNameBubbling(nameOriginal);

        _this.each(function (i, ele) {
          if (!isElement(ele) && !isDocument(ele) && !isWindow(ele)) return;
          removeEvent(ele, name, namespaces, selector, callback);
        });
      });
    }

    return this || _global;
  };

  function on(eventFullName, selector, data, callback, _one) {
    var _this = this || _global;

    if (!isString(eventFullName)) {
      for (var key in eventFullName) {
        this.on(key, selector, data, eventFullName[key], _one);
      }

      return this || _global;
    }

    if (!isString(selector)) {
      if (isUndefined(selector) || isNull(selector)) {
        selector = "";
      } else if (isUndefined(data)) {
        data = selector;
        selector = "";
      } else {
        callback = data;
        data = selector;
        selector = "";
      }
    }

    if (!isFunction(callback)) {
      callback = data;
      data = undefined;
    }

    if (!callback) return this || _global;
    each(getSplitValues(eventFullName), function (i, eventFullName) {
      var _a = parseEventName(eventFullName),
          nameOriginal = _a[0],
          namespaces = _a[1],
          name = getEventNameBubbling(nameOriginal),
          isEventHover = (nameOriginal in eventsHover),
          isEventFocus = (nameOriginal in eventsFocus);

      if (!name) return;

      _this.each(function (i, ele) {
        if (!isElement(ele) && !isDocument(ele) && !isWindow(ele)) return;

        var finalCallback = function finalCallback(event) {
          if (event.target["___i" + event.type]) return event.stopImmediatePropagation(); // Ignoring native event in favor of the upcoming custom one

          if (event.namespace && !hasNamespaces(namespaces, event.namespace.split(eventsNamespacesSeparator))) return;
          if (!selector && (isEventFocus && (event.target !== ele || event.___ot === name) || isEventHover && event.relatedTarget && ele.contains(event.relatedTarget))) return;
          var thisArg = ele;

          if (selector) {
            var target = event.target;

            while (!matches(target, selector)) {
              if (target === ele) return;
              target = target.parentNode;
              if (!target) return;
            }

            thisArg = target;
          }

          Object.defineProperty(event, "currentTarget", {
            configurable: true,
            get: function get() {
              return thisArg;
            }
          });
          Object.defineProperty(event, "delegateTarget", {
            configurable: true,
            get: function get() {
              return ele;
            }
          });
          Object.defineProperty(event, "data", {
            configurable: true,
            get: function get() {
              return data;
            }
          });
          var returnValue = callback.call(thisArg, event, event.___td);

          if (_one) {
            removeEvent(ele, name, namespaces, selector, finalCallback);
          }

          if (returnValue === false) {
            event.preventDefault();
            event.stopPropagation();
          }
        };

        finalCallback.guid = callback.guid = callback.guid || cash.guid++;
        addEvent(ele, name, namespaces, selector, finalCallback);
      });
    });
    return this || _global;
  }

  fn.on = on;

  function one(eventFullName, selector, data, callback) {
    return this.on(eventFullName, selector, data, callback, true);
  }

  ;
  fn.one = one;

  fn.ready = function (callback) {
    var cb = function cb() {
      return setTimeout(callback, 0, cash);
    };

    if (doc.readyState !== "loading") {
      cb();
    } else {
      doc.addEventListener("DOMContentLoaded", cb);
    }

    return this || _global;
  };

  fn.trigger = function (event, data) {
    if (isString(event)) {
      var _a = parseEventName(event),
          nameOriginal = _a[0],
          namespaces = _a[1],
          name_1 = getEventNameBubbling(nameOriginal);

      if (!name_1) return this || _global;
      var type = eventsMouseRe.test(name_1) ? "MouseEvents" : "HTMLEvents";
      event = doc.createEvent(type);
      event.initEvent(name_1, true, true);
      event.namespace = namespaces.join(eventsNamespacesSeparator);
      event.___ot = nameOriginal;
    }

    event.___td = data;
    var isEventFocus = (event.___ot in eventsFocus);
    return this.each(function (i, ele) {
      if (isEventFocus && isFunction(ele[event.___ot])) {
        ele["___i" + event.type] = true; // Ensuring the native event is ignored

        ele[event.___ot]();

        ele["___i" + event.type] = false; // Ensuring the custom event is not ignored
      }

      ele.dispatchEvent(event);
    });
  }; // @optional ./off.ts
  // @optional ./on.ts
  // @optional ./one.ts
  // @optional ./ready.ts
  // @optional ./trigger.ts
  // @require core/pluck.ts
  // @require core/variables.ts


  function getValue(ele) {
    if (ele.multiple && ele.options) return pluck(filter.call(ele.options, function (option) {
      return option.selected && !option.disabled && !option.parentNode.disabled;
    }), "value");
    return ele.value || "";
  }

  var queryEncodeSpaceRe = /%20/g,
      queryEncodeCRLFRe = /\r?\n/g;

  function queryEncode(prop, value) {
    return "&" + encodeURIComponent(prop) + "=" + encodeURIComponent(value.replace(queryEncodeCRLFRe, "\r\n")).replace(queryEncodeSpaceRe, "+");
  }

  var skippableRe = /file|reset|submit|button|image/i,
      checkableRe = /radio|checkbox/i;

  fn.serialize = function () {
    var query = "";
    this.each(function (i, ele) {
      each(ele.elements || [ele], function (i, ele) {
        if (ele.disabled || !ele.name || ele.tagName === "FIELDSET" || skippableRe.test(ele.type) || checkableRe.test(ele.type) && !ele.checked) return;
        var value = getValue(ele);

        if (!isUndefined(value)) {
          var values = isArray(value) ? value : [value];
          each(values, function (i, value) {
            query += queryEncode(ele.name, value);
          });
        }
      });
    });
    return query.slice(1);
  };

  function val(value) {
    if (!arguments.length) return (this || _global)[0] && getValue((this || _global)[0]);
    return this.each(function (i, ele) {
      var isSelect = ele.multiple && ele.options;

      if (isSelect || checkableRe.test(ele.type)) {
        var eleValue_1 = isArray(value) ? map.call(value, String) : isNull(value) ? [] : [String(value)];

        if (isSelect) {
          each(ele.options, function (i, option) {
            option.selected = eleValue_1.indexOf(option.value) >= 0;
          }, true);
        } else {
          ele.checked = eleValue_1.indexOf(ele.value) >= 0;
        }
      } else {
        ele.value = isUndefined(value) || isNull(value) ? "" : value;
      }
    });
  }

  fn.val = val;

  fn.clone = function () {
    return this.map(function (i, ele) {
      return ele.cloneNode(true);
    });
  };

  fn.detach = function (comparator) {
    filtered(this || _global, comparator).each(function (i, ele) {
      if (ele.parentNode) {
        ele.parentNode.removeChild(ele);
      }
    });
    return this || _global;
  };

  var fragmentRe = /^\s*<(\w+)[^>]*>/,
      singleTagRe = /^<(\w+)\s*\/?>(?:<\/\1>)?$/;
  var containers = {
    "*": div,
    tr: tbody,
    td: tr,
    th: tr,
    thead: table,
    tbody: table,
    tfoot: table
  }; //TODO: Create elements inside a document fragment, in order to prevent inline event handlers from firing
  //TODO: Ensure the created elements have the fragment as their parent instead of null, this also ensures we can deal with detatched nodes more reliably

  function parseHTML(html) {
    if (!isString(html)) return [];
    if (singleTagRe.test(html)) return [createElement(RegExp.$1)];
    var fragment = fragmentRe.test(html) && RegExp.$1,
        container = containers[fragment] || containers["*"];
    container.innerHTML = html;
    return cash(container.childNodes).detach().get();
  }

  cash.parseHTML = parseHTML;

  fn.empty = function () {
    return this.each(function (i, ele) {
      while (ele.firstChild) {
        ele.removeChild(ele.firstChild);
      }
    });
  };

  function html(html) {
    if (!arguments.length) return (this || _global)[0] && (this || _global)[0].innerHTML;
    if (isUndefined(html)) return this || _global;
    return this.each(function (i, ele) {
      if (!isElement(ele)) return;
      ele.innerHTML = html;
    });
  }

  fn.html = html;

  fn.remove = function (comparator) {
    filtered(this || _global, comparator).detach().off();
    return this || _global;
  };

  function text(text) {
    if (isUndefined(text)) return (this || _global)[0] ? (this || _global)[0].textContent : "";
    return this.each(function (i, ele) {
      if (!isElement(ele)) return;
      ele.textContent = text;
    });
  }

  ;
  fn.text = text;

  fn.unwrap = function () {
    this.parent().each(function (i, ele) {
      if (ele.tagName === "BODY") return;
      var $ele = cash(ele);
      $ele.replaceWith($ele.children());
    });
    return this || _global;
  };

  fn.offset = function () {
    var ele = (this || _global)[0];
    if (!ele) return;
    var rect = ele.getBoundingClientRect();
    return {
      top: rect.top + win.pageYOffset,
      left: rect.left + win.pageXOffset
    };
  };

  fn.offsetParent = function () {
    return this.map(function (i, ele) {
      var offsetParent = ele.offsetParent;

      while (offsetParent && computeStyle(offsetParent, "position") === "static") {
        offsetParent = offsetParent.offsetParent;
      }

      return offsetParent || docEle;
    });
  };

  fn.position = function () {
    var ele = (this || _global)[0];
    if (!ele) return;
    var isFixed = computeStyle(ele, "position") === "fixed",
        offset = isFixed ? ele.getBoundingClientRect() : this.offset();

    if (!isFixed) {
      var doc_1 = ele.ownerDocument;
      var offsetParent = ele.offsetParent || doc_1.documentElement;

      while ((offsetParent === doc_1.body || offsetParent === doc_1.documentElement) && computeStyle(offsetParent, "position") === "static") {
        offsetParent = offsetParent.parentNode;
      }

      if (offsetParent !== ele && isElement(offsetParent)) {
        var parentOffset = cash(offsetParent).offset();
        offset.top -= parentOffset.top + computeStyleInt(offsetParent, "borderTopWidth");
        offset.left -= parentOffset.left + computeStyleInt(offsetParent, "borderLeftWidth");
      }
    }

    return {
      top: offset.top - computeStyleInt(ele, "marginTop"),
      left: offset.left - computeStyleInt(ele, "marginLeft")
    };
  };

  fn.children = function (comparator) {
    return filtered(cash(unique(pluck(this || _global, function (ele) {
      return ele.children;
    }))), comparator);
  };

  fn.contents = function () {
    return cash(unique(pluck(this || _global, function (ele) {
      return ele.tagName === "IFRAME" ? [ele.contentDocument] : ele.tagName === "TEMPLATE" ? ele.content.childNodes : ele.childNodes;
    })));
  };

  fn.find = function (selector) {
    return cash(unique(pluck(this || _global, function (ele) {
      return find(selector, ele);
    })));
  }; // @require core/variables.ts
  // @require collection/filter.ts
  // @require traversal/find.ts


  var HTMLCDATARe = /^\s*<!(?:\[CDATA\[|--)|(?:\]\]|--)>\s*$/g,
      scriptTypeRe = /^$|^module$|\/(java|ecma)script/i,
      scriptAttributes = ["type", "src", "nonce", "noModule"];

  function evalScripts(node, doc) {
    var collection = cash(node);
    collection.filter("script").add(collection.find("script")).each(function (i, ele) {
      if (scriptTypeRe.test(ele.type) && docEle.contains(ele)) {
        // The script type is supported // The element is attached to the DOM // Using `documentElement` for broader browser support
        var script_1 = createElement("script");
        script_1.text = ele.textContent.replace(HTMLCDATARe, "");
        each(scriptAttributes, function (i, attr) {
          if (ele[attr]) script_1[attr] = ele[attr];
        });
        doc.head.insertBefore(script_1, null);
        doc.head.removeChild(script_1);
      }
    });
  } // @require ./eval_scripts.ts


  function insertElement(anchor, target, left, inside, evaluate) {
    if (inside) {
      // prepend/append
      anchor.insertBefore(target, left ? anchor.firstChild : null);
    } else {
      // before/after
      if (anchor.nodeName === "HTML") {
        anchor.parentNode.replaceChild(target, anchor);
      } else {
        anchor.parentNode.insertBefore(target, left ? anchor : anchor.nextSibling);
      }
    }

    if (evaluate) {
      evalScripts(target, anchor.ownerDocument);
    }
  } // @require ./insert_element.ts


  function insertSelectors(selectors, anchors, inverse, left, inside, reverseLoop1, reverseLoop2, reverseLoop3) {
    each(selectors, function (si, selector) {
      each(cash(selector), function (ti, target) {
        each(cash(anchors), function (ai, anchor) {
          var anchorFinal = inverse ? target : anchor,
              targetFinal = inverse ? anchor : target,
              indexFinal = inverse ? ti : ai;
          insertElement(anchorFinal, !indexFinal ? targetFinal : targetFinal.cloneNode(true), left, inside, !indexFinal);
        }, reverseLoop3);
      }, reverseLoop2);
    }, reverseLoop1);
    return anchors;
  }

  fn.after = function () {
    return insertSelectors(arguments, this || _global, false, false, false, true, true);
  };

  fn.append = function () {
    return insertSelectors(arguments, this || _global, false, false, true);
  };

  fn.appendTo = function (selector) {
    return insertSelectors(arguments, this || _global, true, false, true);
  };

  fn.before = function () {
    return insertSelectors(arguments, this || _global, false, true);
  };

  fn.insertAfter = function (selector) {
    return insertSelectors(arguments, this || _global, true, false, false, false, false, true);
  };

  fn.insertBefore = function (selector) {
    return insertSelectors(arguments, this || _global, true, true);
  };

  fn.prepend = function () {
    return insertSelectors(arguments, this || _global, false, true, true, true, true);
  };

  fn.prependTo = function (selector) {
    return insertSelectors(arguments, this || _global, true, true, true, false, false, true);
  };

  fn.replaceWith = function (selector) {
    return this.before(selector).remove();
  };

  fn.replaceAll = function (selector) {
    cash(selector).replaceWith(this || _global);
    return this || _global;
  };

  fn.wrapAll = function (selector) {
    var structure = cash(selector),
        wrapper = structure[0];

    while (wrapper.children.length) {
      wrapper = wrapper.firstElementChild;
    }

    this.first().before(structure);
    return this.appendTo(wrapper);
  };

  fn.wrap = function (selector) {
    return this.each(function (i, ele) {
      var wrapper = cash(selector)[0];
      cash(ele).wrapAll(!i ? wrapper : wrapper.cloneNode(true));
    });
  };

  fn.wrapInner = function (selector) {
    return this.each(function (i, ele) {
      var $ele = cash(ele),
          contents = $ele.contents();
      contents.length ? contents.wrapAll(selector) : $ele.append(selector);
    });
  };

  fn.has = function (selector) {
    var comparator = isString(selector) ? function (i, ele) {
      return find(selector, ele).length;
    } : function (i, ele) {
      return ele.contains(selector);
    };
    return this.filter(comparator);
  };

  fn.is = function (comparator) {
    var compare = getCompareFunction(comparator);
    return some.call(this || _global, function (ele, i) {
      return compare.call(ele, i, ele);
    });
  };

  fn.next = function (comparator, _all, _until) {
    return filtered(cash(unique(pluck(this || _global, "nextElementSibling", _all, _until))), comparator);
  };

  fn.nextAll = function (comparator) {
    return this.next(comparator, true);
  };

  fn.nextUntil = function (until, comparator) {
    return this.next(comparator, true, until);
  };

  fn.not = function (comparator) {
    var compare = getCompareFunction(comparator);
    return this.filter(function (i, ele) {
      return (!isString(comparator) || isElement(ele)) && !compare.call(ele, i, ele);
    });
  };

  fn.parent = function (comparator) {
    return filtered(cash(unique(pluck(this || _global, "parentNode"))), comparator);
  };

  fn.index = function (selector) {
    var child = selector ? cash(selector)[0] : (this || _global)[0],
        collection = selector ? this || _global : cash(child).parent().children();
    return indexOf.call(collection, child);
  };

  fn.closest = function (comparator) {
    var filtered = this.filter(comparator);
    if (filtered.length) return filtered;
    var $parent = this.parent();
    if (!$parent.length) return filtered;
    return $parent.closest(comparator);
  };

  fn.parents = function (comparator, _until) {
    return filtered(cash(unique(pluck(this || _global, "parentElement", true, _until))), comparator);
  };

  fn.parentsUntil = function (until, comparator) {
    return this.parents(comparator, until);
  };

  fn.prev = function (comparator, _all, _until) {
    return filtered(cash(unique(pluck(this || _global, "previousElementSibling", _all, _until))), comparator);
  };

  fn.prevAll = function (comparator) {
    return this.prev(comparator, true);
  };

  fn.prevUntil = function (until, comparator) {
    return this.prev(comparator, true, until);
  };

  fn.siblings = function (comparator) {
    return filtered(cash(unique(pluck(this || _global, function (ele) {
      return cash(ele).parent().children().not(ele);
    }))), comparator);
  }; // @optional ./children.ts
  // @optional ./closest.ts
  // @optional ./contents.ts
  // @optional ./find.ts
  // @optional ./has.ts
  // @optional ./is.ts
  // @optional ./next.ts
  // @optional ./next_all.ts
  // @optional ./next_until.ts
  // @optional ./not.ts
  // @optional ./parent.ts
  // @optional ./parents.ts
  // @optional ./parents_until.ts
  // @optional ./prev.ts
  // @optional ./prev_all.ts
  // @optional ./prev_until.ts
  // @optional ./siblings.ts
  // @optional attributes/index.ts
  // @optional collection/index.ts
  // @optional css/index.ts
  // @optional data/index.ts
  // @optional dimensions/index.ts
  // @optional effects/index.ts
  // @optional events/index.ts
  // @optional forms/index.ts
  // @optional manipulation/index.ts
  // @optional offset/index.ts
  // @optional traversal/index.ts
  // @require core/index.ts
  // @priority -100
  // @require ./cash.ts
  // @require ./variables.ts


  // Node.js
  exports = cash;
})();

export default exports;